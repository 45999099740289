import React from "react";
import "../style/about.css";
class Home extends React.Component {
  render() {
    return (
      <div>
        <nav style={{padding:"20px"}} class="navigation">
          <a href="index.html" class="logo">
            <span><img style={{height:"60px"}} src="logo.png" alt="logo" /></span>
            {/* <img style={{height:"60px"}} src="logo.png" alt="logo" /> */}
          </a>

          <input type="checkbox" class="menu-btn" id="menu-btn" />
          <label for="menu-btn" class="menu-icon">
            <span class="nav-icon"></span>
          </label>

          <ul class="menu">
            <li>
              <a href="index.html" class="active">
                Home
              </a>
            </li>
            <li>
              <a href="#category">About Us</a>
            </li>
            <li>
              <a href="#popular-bundle-pack">Products</a>
            </li>
            <li>
              <a href="#Live">Live Tracking</a>
            </li>
            <li>
              <a href="#download-app">Contact Us</a>
            </li>
          </ul>

          {/* <div class="right-nav">
               
                <a href="#" class="like">
                    <i class="far fa-heart"></i>
                    <span>0</span>
                </a>
                
                <a href="#" class="cart">
                    <i class="fas fa-shopping-cart"></i>
                    <span>0</span>
                </a>
            </div> */}
        </nav>

        <section  id="search-banner">
          {/* <img alt="bg" class="bg-1" src="images/bg-1.png" />
          <img alt="bg-2" class="bg-2" src="images/bg-2.png" /> */}

          <div class="search-banner-text">
            <h1>Exceedingly good quality</h1>
            <strong>#Aikya</strong>

            <form action="" class="search-box">
              <i class="fas fa-search"></i>

              <input
                type="text"
                class="search-input"
                placeholder="Search your daily groceries"
                name="search"
                required
              />

              <input type="submit" class="search-btn" value="Search" />
            </form>
          </div>
        </section>

        {/* key points starting */}

        <section id="clients">
          <div class="client-box-container">
            <div class="client-box">
              <div class="client-profile">
                {/* <img alt="client" src="images/client-1.jpg" /> */}
                {/* <i
                  style={{ color: "#4eb060", fontSize: "25px" }}
                  class="fas fa-balance-scale"
                ></i> */}
                <i
                  style={{ color: "#4eb060", fontSize: "25px" }}
                  class="fas fa-clipboard-check"
                ></i>

                <div class="profile-text">
                  <strong> High Quality </strong>
                  <span>100%</span>
                </div>
              </div>

              <div class="rating">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </div>

              <p>
                We are committed to provide <i>Top Grade quality</i> products to
                our customers around the world.{" "}
              </p>
            </div>

            <div class="client-box">
              <div class="client-profile">
                {/* <img alt="client" src="images/client-2.jpg" /> */}
                <i
                  style={{ color: "#4eb060", fontSize: "25px" }}
                  class="fas fa-handshake"
                ></i>

                <div class="profile-text">
                  <strong>Trust Worthy </strong>
                  <span>Familiarity breeds trust</span>
                </div>
              </div>

              <div class="rating">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </div>

              <p>
                We believe that <i>trust is the keystone</i> to business and we
                ascertain that we will surpass your expectations.{" "}
              </p>
            </div>

            <div class="client-box">
              <div class="client-profile">
                {/* <img alt="client" src="images/client-3.jpg" /> */}
                <i
                  style={{ color: "#4eb060", fontSize: "25px" }}
                  class="fas fa-globe-asia"
                ></i>

                <div class="profile-text">
                  <strong>Worldwide Services </strong>
                  <span>Easy Delivery</span>
                </div>
              </div>

              <div class="rating">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </div>

              <p>
                We ensure to provide services to our customers{" "}
                <i>around the world </i>
                and to update periodically of in-process orders.{" "}
              </p>
            </div>
          </div>
        </section>
        {/* key points starting ending*/}
        {/* about us starting */}
        <section id="category">
          <div class="category-heading">
            <h2 style={{ textAlign: "center" }}>About Us</h2>
          </div>

          <div className="flexClass">
            <div className="aboutView">
              <h4>
                <i>The journey to Aikya</i>
              </h4>
              <p style={{ letterSpacing: "1.5px" }}>
                Welcome to Aikyastar international private limited, your number
                one source for all premium quality products. We are a young
                dynamic company that aim to be the most recognized name when it
                comes to Exporting quality products and to deliver best service
                to our clients.{" "}
              </p>
            </div>

            <div className="imgClass">
              <img style={{ borderRadius: "10px" }} src="home1.jpg" alt="img" />
            </div>
          </div>
          {/*secondabout  */}
          <div style={{ marginTop: "60px" }} className="flexClass">
            <div className="imgClass">
              <img style={{ borderRadius: "10px" }} src="home2.jpg" alt="img" />
            </div>

            <div className="aboutView">
              <h4 className="newh4">
                <i>Transforming </i>
              </h4>
              <p
                style={{
                  textAlign: "left",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  letterSpacing: "1.5px",
                }}
              >
                Aikyastar international private limited is headquartered in
                Chennai, TN, India. We are specialized in exporting apparels,
                Agro products, coconuts and coir products such as Coco peat,
                Coconut Fibre, Coconut Fibre Rope. All the products are quality
                tested by experts.
              </p>
            </div>
          </div>
        </section>
        {/* about us ending */}

        <section id="popular-bundle-pack">
          <div class="product-heading">
            <h3>Popular Products</h3>
          </div>

          <div class="product-container">
            <div class="product-box">
              <img alt="apple" src="coco.png" />
              <strong>Coconut</strong>
              <a href="#" class="cart-btn">
                <i class="fas fa-shopping-bag"></i> Enquiry
              </a>
              <a href="#" class="like-btn">
                <i class="far fa-heart"></i>
              </a>
            </div>
            <div class="product-box">
              <img alt="apple" src="semi.jpg" />
              <strong>Semi Husked Coconut</strong>
              <a href="#" class="cart-btn">
                <i class="fas fa-shopping-bag"></i> Enquiry
              </a>
              <a href="#" class="like-btn">
                <i class="far fa-heart"></i>
              </a>
            </div>
            <div class="product-box">
              <img alt="apple" src="ful.jpg" />
              <strong>Fully Husked Coconut</strong>
              <a href="#" class="cart-btn">
                <i class="fas fa-shopping-bag"></i> Enquiry
              </a>
              <a href="#" class="like-btn">
                <i class="far fa-heart"></i>
              </a>
            </div>
            <div class="product-box">
              <img alt="apple" src="cc.jpg" />
              <strong> Coconut Charcoal</strong>
              <a href="#" class="cart-btn">
                <i class="fas fa-shopping-bag"></i> Enquiry
              </a>
              <a href="#" class="like-btn">
                <i class="far fa-heart"></i>
              </a>
            </div>
            <div class="product-box">
              <img alt="apple" src="cd.jpg" />
              <strong> Coir Disk</strong>
              <a href="#" class="cart-btn">
                <i class="fas fa-shopping-bag"></i> Enquiry
              </a>
              <a href="#" class="like-btn">
                <i class="far fa-heart"></i>
              </a>
            </div>
            <div class="product-box">
              <img alt="apple" src="cb.jpg" />
              <strong> Coir Blocks</strong>
              <a href="#" class="cart-btn">
                <i class="fas fa-shopping-bag"></i> Enquiry
              </a>
              <a href="#" class="like-btn">
                <i class="far fa-heart"></i>
              </a>
            </div>
            <div class="product-box">
              <img alt="apple" src="pb.jpg" />
              <strong> Peat blocks</strong>
              <a href="#" class="cart-btn">
                <i class="fas fa-shopping-bag"></i> Enquiry
              </a>
              <a href="#" class="like-btn">
                <i class="far fa-heart"></i>
              </a>
            </div>
            <div class="product-box">
              <img alt="apple" src="cbb.jpg" />
              <strong> Coir bales</strong>
              <a href="#" class="cart-btn">
                <i class="fas fa-shopping-bag"></i> Enquiry
              </a>
              <a href="#" class="like-btn">
                <i class="far fa-heart"></i>
              </a>
            </div>
            <div class="product-box">
              <img alt="apple" src="cf.jpg" />
              <strong> Coconut fibre</strong>
              <a href="#" class="cart-btn">
                <i class="fas fa-shopping-bag"></i> Enquiry
              </a>
              <a href="#" class="like-btn">
                <i class="far fa-heart"></i>
              </a>
            </div>
          </div>
        </section>

        <section id="download-app">
          <div class="app-img">
            <img alt="app" src="map.jpeg" />
          </div>

          <div class="download-app-text">
            <strong>Contact Us</strong>
            <p>
              Aikyastar International Private Limited.
              <br />
              <br />
              No.10/2 Flat No. S3, Perumal Kovil Street, Agraharam, Korattur,
              Chennai-600076
              <br />
              <br />
              Tamil Nadu, INDIA
              <br />
              <br />
              <i style={{ marginRight: "10px" }} class="fa fa-phone"></i>Mob:
              +91 900 3476 396
              <br />
              <i style={{ marginRight: "10px" }} class="fa fa-envelope"></i>
              Email: info@aikyastar.com
              <br />
              <i style={{ marginRight: "10px" }} class="fas fa-globe"></i>
              Web: www.aikyastar.com
            </p>
          </div>
        </section>
        <div id="Live">
          <iframe
            style={{ height: "300px", width: "100%" }}
            src="https://goo.gl/maps/1nQYf2zRu67HFSYp7"
          ></iframe>
        </div>
        <footer>
          <div class="footer-container">
            <div class="footer-logo">
              <a href="#">Aikya</a>

              <div class="footer-social">
                <a href="#">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i class="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i class="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i class="fab fa-youtube"></i>
                </a>
              </div>
            </div>

            <div class="footer-links">
              <strong>Menu</strong>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Products</a>
                </li>
                <li>
                  <a href="#">Live Tracking</a>
                </li>{" "}
                <li>
                  <a href="#">Contact Us</a>
                </li>
              </ul>
            </div>

            <div class="footer-links">
              <strong>Contact</strong>
              <ul>
                <li>
                  <a href="#">Phone : +91 900 3476 396</a>
                </li>
                <li>
                  <a href="#">Email : info@aikyastar.com</a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Home;
